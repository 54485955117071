/* // axios
//axios.defaults.baseURL = 'http://51.38.57.172:9030/api/'
//axios.defaults.baseURL = 'http://89.147.102.129:9030/api/'
//axios.defaults.baseURL = 'https://jra.mitigarisk.com/api/'
axios.defaults.baseURL = 'https://dev.jra.mitigarisk.com/api/'
//axios.defaults.baseURL = 'http://145.239.11.24:9029/api/'
//axios.defaults.baseURL = 'http://145.239.11.24:9030/api/'
//axios.defaults.baseURL = 'https://test.jra.mitigarisk.com/api/'
 */
// axios
import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://dev.jra.mitigarisk.com/api/'
})

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

export default apiClient
