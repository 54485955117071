/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth/authService'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)
//const redirection = window.open('https://jra.mitigarisk.com/digira/', '_blank')
const redirection = async () => {
  if (localStorage.getItem('accessToken') && (localStorage.getItem('accessToken') !== null) && localStorage.getItem('accessToken').length) {
    window.open('https://bot.mitigarisk.com/', '_blank')
  }
}
const ifAuthenticated = async (to, from, next) => {
  if (localStorage.getItem('accessToken') && (localStorage.getItem('accessToken') !== null) && localStorage.getItem('accessToken').length) {
    next()
    return
  }
  next('/pages/login')
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard'
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          beforeEnter: ifAuthenticated,
          component: () => import('./views_mitigarisk/dashboard/dashboard.vue'),
          meta: {
            rule: 'editor'
          }
        },

        // =============================================================================
        // ------- Start MooZiSupport Application Routes -------------------------------
        // =============================================================================


        {
          path: '/Utilisateurs',
          beforeEnter: ifAuthenticated,
          name: 'users',
          component: () => import('./views_mitigarisk/users/DataListListView.vue'),
          meta: {
            rule: 'editor'
          }
        },
        
        {
          path: '/Formations',
          beforeEnter: ifAuthenticated,
          name: 'Formations',
          component: () => import('./views_mitigarisk/formations/DataListFormation.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Functions',
          beforeEnter: ifAuthenticated,
          name: 'Functions',
          component: () => import('./views_mitigarisk/functions/DataListFunctions.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        /*   {
          path: '/Nationalities',
          beforeEnter: ifAuthenticated,
          name: 'Nationalities',
          component: () => import('./views_mitigarisk/nationalities/DataListNationalities.vue'),
          meta: {
            rule: 'editor'
          }
        }, */
        {
          path: '/Sites',
          beforeEnter: ifAuthenticated,
          name: 'Sites',
          component: () => import('./views_mitigarisk/sites/DataListSites.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Zones',
          beforeEnter: ifAuthenticated,
          name: 'Zones',
          component: () => import('./views_mitigarisk/zones/DataListZones.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/RiskProfiles',
          beforeEnter: ifAuthenticated,
          name: 'RiskProfiles',
          component: () => import('./views_mitigarisk/riskProfiles/DataListRiskProfile.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Companies',
          beforeEnter: ifAuthenticated,
          name: 'Companies',
          component: () => import('./views_mitigarisk/companies/DataListCompanies.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Equipments',
          beforeEnter: ifAuthenticated,
          name: 'Equipments',
          component: () => import('./views_mitigarisk/equipements/DataListEquipements.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Activated-equipments',
          beforeEnter: ifAuthenticated,
          name: 'Activated-equipments',
          component: () => import('./views_mitigarisk/equipements/DataListEquipements_validate.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Inactivation-equipments',
          beforeEnter: ifAuthenticated,
          name: 'Inactivation-equipments',
          component: () => import('./views_mitigarisk/equipements/DataListEquipements_encours.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Rejected-equipments',
          beforeEnter: ifAuthenticated,
          name: 'Rejected-equipments',
          component: () => import('./views_mitigarisk/equipements/DataListEquipements_rejete.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/EquipmentTypes',
          beforeEnter: ifAuthenticated,
          name: 'EquipmentTypes',
          component: () => import('./views_mitigarisk/equipmentType/DataListEquipmentType.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/LiftingEquipments',
          beforeEnter: ifAuthenticated,
          name: 'LiftingEquipments',
          component: () => import('./views_mitigarisk/liftingEquipments/DataListLiftingEquipments.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Activated-liftingEquipments',
          beforeEnter: ifAuthenticated,
          name: 'Activated-liftingEquipments',
          component: () => import('./views_mitigarisk/liftingEquipments/DataListLiftingEquipments_validate.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Rejected-liftingEquipments',
          beforeEnter: ifAuthenticated,
          name: 'Rejected-liftingEquipments',
          component: () => import('./views_mitigarisk/liftingEquipments/DataListLiftingEquipments_rejete.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Inactivation-liftingEquipments',
          beforeEnter: ifAuthenticated,
          name: 'Inactivation-liftingEquipments',
          component: () => import('./views_mitigarisk/liftingEquipments/DataListLiftingEquipments_encours.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Vehicules',
          beforeEnter: ifAuthenticated,
          name: 'Vehicules',
          component: () => import('./views_mitigarisk/vehicule/DataListVehicule.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Inactivation-vehicle',
          beforeEnter: ifAuthenticated,
          name: 'Inactivation-vehicle',
          component: () => import('./views_mitigarisk/vehicule/DataListVehicule_encours.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Activated-vehicle',
          beforeEnter: ifAuthenticated,
          name: 'Activated-vehicle',
          component: () => import('./views_mitigarisk/vehicule/DataListVehicule_validate.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Rejected-vehicle',
          beforeEnter: ifAuthenticated,
          name: 'Rejected-vehicle',
          component: () => import('./views_mitigarisk/vehicule/DataListVehicule_rejete.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Tasks',
          beforeEnter: ifAuthenticated,
          name: 'Tasks',
          component: () => import('./views_mitigarisk/tasks/DataListTasks.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Teams',
          beforeEnter: ifAuthenticated,
          name: 'Teams',
          component: () => import('./views_mitigarisk/teams/DataListTeams.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Activated-teams',
          beforeEnter: ifAuthenticated,
          name: 'Activated-teams',
          component: () => import('./views_mitigarisk/teams/DataListTeams_validate.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Inactivation-teams',
          beforeEnter: ifAuthenticated,
          name: 'Inactivation-teams',
          component: () => import('./views_mitigarisk/teams/DataListTeams_encours.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Rejected-teams',
          beforeEnter: ifAuthenticated,
          name: 'Rejected-teams',
          component: () => import('./views_mitigarisk/teams/DataListTeams_rejete.vue'),
          meta: {
            rule: 'editor'
          }
        },
        /* {
          path: '/Missions',
          beforeEnter: ifAuthenticated,
          name: 'Missions',
          component: () => import('./views_mitigarisk/missions/DataListMissions.vue'),
          meta: {
            rule: 'editor'
          }
        }, */
        {
          path: '/Staff_Formations',
          beforeEnter: ifAuthenticated,
          name: 'Staff_Formations',
          component: () => import('./views_mitigarisk/staffHasFormations/DataListStaffHasFormations.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Staffs',
          beforeEnter: ifAuthenticated,
          name: 'Staffs',
          component: () => import('./views_mitigarisk/staffs/DataListStaffs.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Meetings',
          beforeEnter: ifAuthenticated,
          name: 'Meetings',
          component: () => import('./views_mitigarisk/meetings/DataListMeetings.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Validations',
          beforeEnter: ifAuthenticated,
          name: 'Validations',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListPlanificationValidation.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/SecurityQuestions',
          beforeEnter: ifAuthenticated,
          name: 'SecurityQuestions',
          component: () => import('./views_mitigarisk/securityQuestions/DataListSecurityQuestions.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/SecurityEvaluations',
          beforeEnter: ifAuthenticated,
          name: 'SecurityEvaluations',
          component: () => import('./views_mitigarisk/securityEvaluations/DataListSecurityEvalutions.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Libraries',
          beforeEnter: ifAuthenticated,
          name: 'Libraries',
          component: () => import('./views_mitigarisk/Libraries/DataListLibraries.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Projects',
          beforeEnter: ifAuthenticated,
          name: 'Projects',
          component: () => import('./views_mitigarisk/projects/DataListProjets.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Assignations',
          beforeEnter: ifAuthenticated,
          name: 'Assignations',
          component: () => import('./views_mitigarisk/projects/DataListAssignation.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Inactivation-staffs',
          beforeEnter: ifAuthenticated,
          name: 'Inactivation-staffs',
          component: () => import('./views_mitigarisk/staffs/DataListStaffs_encours.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Activated-staffs',
          beforeEnter: ifAuthenticated,
          name: 'Activated-staffs',
          component: () => import('./views_mitigarisk/staffs/DataListStaffs_validate.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Rejected-staffs',
          beforeEnter: ifAuthenticated,
          name: 'Rejected-staffs',
          component: () => import('./views_mitigarisk/staffs/DataListStaffs_rejete.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        /*   {
          path: '/Journeys',
          beforeEnter: ifAuthenticated,
          name: 'Staffs',
          component: () => import('./views_mitigarisk/journeys/DataListJourneys.vue'),
          meta: {
            rule: 'editor'
          }
        },  */
        {
          path: '/Measures',
          beforeEnter: ifAuthenticated,
          name: 'Measures',
          component: () => import('./views_mitigarisk/recommendedSefetyMeasures/DataListRecommendedSefetyMeasures.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Categories',
          beforeEnter: ifAuthenticated,
          name: 'Categories',
          component: () => import('./views_mitigarisk/categories/DataListCategories.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Questions',
          beforeEnter: ifAuthenticated,
          name: 'Questions',
          component: () => import('./views_mitigarisk/questions/DataListQuestions.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/RiskZoneAssessments',
          beforeEnter: ifAuthenticated,
          name: 'RiskZoneAssessments',
          component: () => import('./views_mitigarisk/riskZoneAssessments/DataListRiskZoneAssessements.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/JobRiskAssessments',
          beforeEnter: ifAuthenticated,
          name: 'JobRiskAssessments',
          component: () => import('./views_mitigarisk/jobRiskAssessments/DataListjobRiskAssessments.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/Activities',
          beforeEnter: ifAuthenticated,
          name: 'Activities',
          component: () => import('./views_mitigarisk/activities/DataListActivities.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        /*  {
          path: '/EvacuationPlans',
          beforeEnter: ifAuthenticated,
          name: 'EvacuationPlans',
          component: () => import('./views_mitigarisk/evacuationPlans/DataListEvacuationPlans.vue'),
          meta: {
            rule: 'editor'
          }
        },  */
        {
          path: '/StaffHasEquipments',
          beforeEnter: ifAuthenticated,
          name: 'StaffHasEquipments',
          component: () => import('./views_mitigarisk/staffHasEquipments/DataListStaffHasEquipments.vue'),
          meta: {
            rule: 'editor'
          }
        }, 
        {
          path: '/InsuranceCompanies',
          beforeEnter: ifAuthenticated,
          name: 'InsuranceCompanies',
          component: () => import('./views_mitigarisk/assururances/DataListAssurances.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/MissionEvacuations',
          beforeEnter: ifAuthenticated,
          name: 'MissionEvacuations',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListEvacuationPlans.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/MissionJobRiskAssessments',
          beforeEnter: ifAuthenticated,
          name: 'MissionJobRiskAssessments',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListjobRiskAssessments.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/MissionEvalutionSecurity',
          beforeEnter: ifAuthenticated,
          name: 'MissionEvalutionSecurity',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListEvalutionSecurity.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Reporting',
          beforeEnter: redirection,
          name: 'Reporting',
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/MissionJourneys',
          beforeEnter: ifAuthenticated,
          name: 'MissionJourneys',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListJourneys.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/MissionRiskZones',
          beforeEnter: ifAuthenticated,
          name: 'MissionRiskZones',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListRiskZoneAssessements.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/MissionPlanifications',
          beforeEnter: ifAuthenticated,
          name: 'MissionPlanifications',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListMissions.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Planifications',
          beforeEnter: ifAuthenticated,
          name: 'Planifications',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListPlanification.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Unclosed-missions',
          beforeEnter: ifAuthenticated,
          name: 'Unclosed-missions',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListPlanification_NonCloturer.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Closed-missions',
          beforeEnter: ifAuthenticated,
          name: 'Closed-missions',
          component: () => import('./views_mitigarisk/PlanificationMissions/DataListPlanification _cloturer.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/Risks',
          beforeEnter: ifAuthenticated,
          name: 'Risks',
          component: () => import('./views_mitigarisk/risks/DataListRisk.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/Profil',
          name: 'page-Profil',
          beforeEnter: ifAuthenticated,
          component: () => import('./views/pages/login/MonProfil'),
          meta: {
            rule: 'editor'
          }
        },

        {
          path: '/pages/Profile',
          name: 'page-Profile',
          beforeEnter: ifAuthenticated,
          component: () => import('./views/pages/login/MonProfile'),
          meta: {
            rule: 'editor'
          }
        }

      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('./layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('./views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/Changelogin',
          name: 'page-Changelogin',
          component: () => import('./views/pages/login/Changelogin'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('./views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/reset-password',
          name: 'password-reset',
          component: () => import('./views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        /*  {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('./views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        }, */
        /* 
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('./views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('./views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('./views/pages/ComingSoon.vue'),
          meta: {
            rule: 'editor'
          }
        }, */
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('./views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        }
        /*  {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('./views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('./views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('./views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          } 
        }*/
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired) {
      if (!(auth.isAuthenticated() || firebaseCurrentUser)) {
        router.push({ path: '/pages/login', query: { to: to.path } })
      }
    }

    return next()
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

  })

})

export default router
